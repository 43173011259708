import * as ActionConstants from "../constants/ReduxConstants";
import { floorScreenController } from "../constants/AppConstants";
let initialState = {
  floorData: [],
  changeFloorList: [],
  activeScreen: floorScreenController.floorListing,
  floorByIdList: {},
  takeOrderFloorList: [],
};

export const floorListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.FLOOR_LIST:
      return {
        ...state,
        floorData: action.data,
      };

    case ActionConstants.CHANGE_FLOOR_LIST:
      return {
        ...state,
        changeFloorList: action.data,
      };

    case ActionConstants.DELETE_FLOOR:
      return {
        ...state,
        floorData: state.floorData.filter(
          (item, index) => item._id !== action.data
        ),
      };

    case ActionConstants.FLOOR_ACTIVE_SCREEN:
      return {
        ...state,
        activeLeftScreen: action.data,
      };

    case ActionConstants.FLOOR_BYID_LIST:
      return {
        ...state,
        floorByIdList: action.data,
      };

    case ActionConstants.TAKE_ORDER_FLOOR_LIST:
      return {
        ...state,
        takeOrderFloorList: action.data,
      };
    case ActionConstants.RESET_TAKE_ORDER_SCREEN_STATE:
      return initialState;

    default:
      return state;
  }
};
