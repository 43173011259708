import * as ActionConstants from "../constants/ReduxConstants";

let initialState = {
  customerData: {
    docs: [],
    total: 0,
    limit: 15,
    page: 1,
    pages: 1,
  },
  customerDetails: [],
  getSingleCustomer: {},
  selectedCustomer: [],
  customerBillList: [],
  itemsList: [],
  unpaidCustomerList: [],
};

export const customerList = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.CUSTOMER_LIST:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          docs: state.customerData.docs.concat(
            action.data.docs.filter(
              (value) => !state.customerData.docs.includes(value)
            )
          ),

          page: action?.data?.page,
          limit: action?.data?.limit,
          pages: action?.data?.pages,
          total: action?.data?.total,
        },
      };

    case ActionConstants.EDIT_CUSTOMER_NAME:
      const customerList = state?.customerData?.docs?.map((customer) => {
        if (customer?._id === action.customerId) {
          return action.data;
        }
        return customer;
      });

      return {
        ...state,
        customerData: {
          ...state.customerData,
          docs: customerList,
        },
      };

    case ActionConstants.DELETE_CUSTOMER:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          docs: state.customerData.docs.filter(
            (item, index) => item._id !== action.data
          ),
        },
      };

    case ActionConstants.CUSTOMER_DETAILS_LIST:
      return {
        ...state,
        customerDetails: action?.data,
      };

    case ActionConstants.CUSTOMER_BY_ID_LIST:
      return {
        ...state,
        getSingleCustomer: action?.data,
      };

    case ActionConstants.SELECTED_CUSTOMER_LIST:
      return {
        ...state,
        selectedCustomer: action?.data,
      };

    case ActionConstants.GET_CUSTOMERS_BILL_LIST:
      return {
        ...state,
        customerBillList: action?.data,
      };

    case ActionConstants.GET_ITEMS_LIST:
      return {
        ...state,
        itemsList: action?.data,
      };

    case ActionConstants.UNPAID_CUSTOMER_LIST:
      return {
        ...state,
        unpaidCustomerList: action?.data,
      };

    case ActionConstants.CLEAR_INFINITE_PAGE_DATA:
      return initialState;

    default:
      return state;
  }
};
