import * as ActionConstants from "../constants/ReduxConstants";
import {
  leftCombinePOSDrawerScreen,
  rightCombinePOSDrawerScreen,
} from "../constants/AppConstants";

const defaultState = {
  selectedTableNumber: "",
  selectedCustomer: {},
  customerList: [],
  activeLeftScreen: leftCombinePOSDrawerScreen.tableListingScreen,
  activeRightScreen: rightCombinePOSDrawerScreen.emptyScreen,
  categoriesList: [],
  selectedCategory: {},
  itemList: [],
  selectedItemList: [],
  isQuickOrder: Boolean,
};

export const twoInOnePOSState = (state = defaultState, action) => {
  switch (action.type) {
    case ActionConstants.SET_TABLE_NUMBER:
      return {
        ...state,
        selectedTableNumber: action.data,
      };

    case ActionConstants.RESET_TABLE_NUMBER:
      return {
        ...state,
        selectedTableNumber: "",
      };
    case ActionConstants.SET_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.data,
      };
    case ActionConstants.GET_USER_LIST_FROM_TABLE_SELECTION:
      return {
        ...state,
        customerList: action.data,
      };
    case ActionConstants.LEFT_CHANGE_SCREEN:
      return {
        ...state,
        activeLeftScreen: action.data,
      };
    case ActionConstants.RIGHT_CHANGE_SCREEN:
      return {
        ...state,
        activeRightScreen: action.data,
      };
    case ActionConstants.QUICK_ORDER:
      return {
        ...state,
        isQuickOrder: action.data,
      };
    case ActionConstants.GET_TWO_IN_ONE_POST_CATEGORIES:
      return {
        ...state,
        categoriesList: action.data,
      };
    case ActionConstants.SET_SELECTED_CATEGORY_OBJECT:
      return {
        ...state,
        selectedCategory: action.data,
      };
    case ActionConstants.SET_SELECTED_ITEM_LIST:
      return {
        ...state,
        selectedItemList: action.data,
      };
    case ActionConstants.GET_TWO_IN_ONE_ITEM_LIST_BASED_ON_CATEGORY:
      return {
        ...state,
        itemList: action.data.items,
      };
    case ActionConstants.RESET_TWO_IN_ONE_STATE:
      return defaultState;
    default:
      return state;
  }
};
