import * as ActionConstants from "../constants/ReduxConstants";

const initialMenuCollapseIndexState = {
  categoryIndex: -1,
  userIndex: -1,
  reportsIndex: -1,
};

export const menuCollapseIndex = (
  state = initialMenuCollapseIndexState,
  action
) => {
  switch (action.type) {
    case ActionConstants.BAR_MENU_COLLAPSE_INDEX:
      const { categoryIndex, userIndex, reportsIndex } = action.data;
      return {
        categoryIndex,
        userIndex,
        reportsIndex,
      };

    default:
      return state;
  }
};
