import * as ActionConstants from "../constants/ReduxConstants";

export const stationMasterList = (state = [], action) => {
  switch (action.type) {
    case ActionConstants.GET_ALL_MASTER_STATION_LIST:
      return action.data;

    default:
      return state;
  }
};
