import * as ActionConstants from "../constants/ReduxConstants";

export const menuList = (state = [], action) => {
  switch (action.type) {
    case ActionConstants.MENU_LIST:
      return action.data ? action.data : state;

    case ActionConstants.UPDATE_CATEGORY:
      const categoryList = state.map((category) => {
        if (category?._id === action.categoryId) {
          return action.data;
        }
        return category;
      });
      return categoryList;

    case ActionConstants.UPDATE_SUB_CATEGORY: {
      const list = state.map((category) => {
        if (category._id === action.data?.parentCategoryId) {
          const subcategoryList = category.items.map((items) => {
            if (items._id === action?.data?._id) {
              return action.data;
            } else {
              return items;
            }
          });
          return {
            ...category,
            items: subcategoryList,
          };
        } else {
          return category;
        }
      });
      return list;
    }

    default:
      return state;
  }
};
