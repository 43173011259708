import * as ActionConstants from "../constants/ReduxConstants";
import { BG_COLOR } from "../constants/AppConstants";

const dayWiseArray = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const settingsConfiguration = (state = [], action) => {
  switch (action.type) {
    case ActionConstants.WEEKLY_SETTINGS:
      let newList = action.data.weekdays.map((e, index) => {
        let openingHours = e.openingHours.map((innerElement) => {
          let startTime = innerElement.startTime.split(":");
          let startDate = new Date();
          startDate.setHours(parseInt(startTime[0]), parseInt(startTime[1]));

          let endTime = innerElement.endTime.split(":");
          let endDate = new Date();
          endDate.setHours(parseInt(endTime[0]), parseInt(endTime[1]), 0, 0);

          return {
            startTime: startDate,
            endTime: endDate,
          };
        });

        let midnightEndTime = e.midnightEndTime.split(":");
        let midnightEndDate = new Date();
        midnightEndDate.setHours(
          parseInt(midnightEndTime[0]),
          parseInt(midnightEndTime[1]),
          0,
          0
        );

        return {
          openingHours: openingHours,
          key: index,
          day: dayWiseArray[index],
          isOpen: e.isOpen,
          isMidnight: e.isMidnight,
          midnightEndTime: midnightEndDate,
        };
      });
      let newObject = Object.assign(action.data, { weekdays: newList });
      return newObject;

    default:
      return state;
  }
};

export const botMessagesConfiguration = (state = [], action) => {
  switch (action.type) {
    case ActionConstants.BOT_MESSAGES:
      return action.data;

    default:
      return state;
  }
};

export const globalConfiguration = (state = [], action) => {
  switch (action.type) {
    case ActionConstants.GLOBAL_CONFIGURATION:
      return action.data;

    default:
      return state;
  }
};

let defaultMessageState = {
  totalMessages: 0,
  facebook: 0,
  sms: 0,
  whatsApp: 0,
  totalUsed: 0,
  pendingMessages: 0,
  chartConfiguration: {
    chart: {
      type: "bar",
      height: 60,
    },
    title: {
      text: "",
    },
    xAxis: {
      visible: false,
      categories: ["Total Messages"],
    },
    yAxis: {
      visible: false,
      lineWidth: 0,
      title: {
        text: "",
      },
    },
    colors: ["#D4D8DC", "#00C0EF", "#1877F2", "#60D66A"],
    legend: {
      reversed: false,
    },
    credits: {
      enabled: false,
    },
    labels: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },
    },
    series: [],
  },
};

export const transactionsExchanged = (state = defaultMessageState, action) => {
  switch (action.type) {
    case ActionConstants.MESSAGES_EXCHANGED:
      let responseData = action.data;
      let pendingMessages =
        responseData.total -
        (responseData.used.facebook +
          responseData.used.sms +
          responseData.used.whatsApp +
          responseData.used.pos +
          responseData.used.webView);
      return {
        totalMessages: responseData.total,
        facebook: responseData.used.facebook,
        manualOrder: responseData.used.pos,
        sms: responseData.used.sms,
        whatsApp: responseData.used.whatsApp,
        webView: responseData.used.webView,
        totalUsed:
          responseData.used.facebook +
          responseData.used.sms +
          responseData.used.whatsApp +
          responseData.used.pos +
          responseData.used.webView,
        pendingMessages: pendingMessages,
        chartConfiguration: {
          chart: {
            type: "bar",
            height: 60,
            backgroundColor: BG_COLOR,
          },
          title: {
            text: "",
          },
          xAxis: {
            visible: false,
            categories: ["Total Messages"],
          },
          yAxis: {
            visible: false,
            lineWidth: 0,
            title: {
              text: "",
            },
          },
          colors: [
            "#D4D8DC",
            "#115293",
            "#FF7GG",
            "#00C0EF",
            "#1877F2",
            "#60D66A",
          ],
          legend: {
            reversed: false,
          },
          credits: {
            enabled: false,
          },
          labels: {
            enabled: false,
          },
          plotOptions: {
            series: {
              stacking: "normal",
            },
          },
          series: [
            {
              name: "Pending Messages",
              showInLegend: false,
              data: [pendingMessages],
            },
            {
              name: "WebView",
              showInLegend: false,
              data: [responseData.used.webView],
            },
            {
              name: "Take Order",
              showInLegend: false,
              data: [responseData.used.pos],
            },
            {
              name: "SMS",
              showInLegend: false,
              data: [responseData.used.sms],
            },
            {
              name: "Facebook",
              showInLegend: false,
              data: [responseData.used.facebook],
            },
            {
              name: "WhatsApp",
              showInLegend: false,
              data: [responseData.used.whatsApp],
            },
          ],
        },
      };

    default:
      return state;
  }
};

export const organizationConfig = (state = "", action) => {
  switch (action.type) {
    case ActionConstants.ORGANIZATION_EMAIL_CONFIG:
      return action.data;

    default:
      return state;
  }
};

export const isSkipped = (state = false, action) => {
  switch (action.type) {
    case ActionConstants.IS_SKIPPED:
      return action.data;

    default:
      return state;
  }
};

export const currentSelectedMessageChannel = (state = "whatsApp", action) => {
  switch (action.type) {
    case ActionConstants.CURRENT_SELECTED_MESSAGE_CHANNEL:
      return action.data;

    default:
      return state;
  }
};
