import React from "react";

const Loader = ({ size = "" }) => {
  return (
    <div>
      <span className={`ant-spin-dot ant-spin-dot-spin ${size}`}>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </span>
    </div>
  );
};

export default Loader;
