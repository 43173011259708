import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import Notifications from "react-notify-toast";

import configureStore from "./store/configureStore";

const store = configureStore();
const appRouting = (
  <Provider store={store}>
    <Notifications options={{ zIndex: 2000 }} />
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);

ReactDOM.render(appRouting, document.getElementById("root"));

serviceWorker.unregister();
