import * as ActionConstants from "../constants/ReduxConstants";

const initialState = {
  variousItemList: [],
};

export const variousReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.GET_VARIOUS_ITEMS_LIST:
      return {
        ...state,
        variousItemList: action.data,
      };

    default:
      return state;
  }
};
