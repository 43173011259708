import * as ActionConstants from "../constants/ReduxConstants";

export const liveOrderList = (state = [], action) => {
  switch (action.type) {
    case ActionConstants.LIVE_ORDER:
      return action.data;

    case ActionConstants.ORDER_GENERATED:
      let newOrderArray = [action.data.data];
      let preparedNewArray = state.concat(newOrderArray);
      return preparedNewArray;

    case ActionConstants.ORDER_UPDATED:
      let orderIndex = state.findIndex(
        (obj) => obj._id === action.data.data._id
      );
      state[orderIndex] = action.data.data;
      return [...state];

    case ActionConstants.ORDER_CANCELLED:
      return state.filter((e) => e._id !== action.data.data.id);

    default:
      return state;
  }
};

export const queueOrdersList = (state = [], action) => {
  switch (action.type) {
    case ActionConstants.GET_QUEUE_ORDER:
      return action.data;

    default:
      return state;
  }
};

export const isLiveOrderOpen = (state = false, action) => {
  switch (action.type) {
    case ActionConstants.IS_LIVE_ORDER_OPEN:
      return action.data;

    default:
      return state;
  }
};
