import { combineReducers } from "redux";

import { loadingBarReducer } from "react-redux-loading-bar";
import { forgotPassword, forgotPasswordInfo } from "./AuthReducer";
import { isFullScreen } from "./FullScreenReducer";
import * as ActionConstants from "../constants/ReduxConstants";

import { customerList } from "./CustomerReducer";
import {
  orderDetails,
  orderHistoryReducer,
  selectedLiveOrder,
} from "./OrderReducer";
import { byPassPaymentList } from "./ByPassPaymentReducer";
import { menuList } from "./MenuReducer";
import { acceptanceStatus } from "./AcceptanceReducer";
import {
  isLiveOrderOpen,
  liveOrderList,
  queueOrdersList,
} from "./LiveOrderReducer";
import { paymentUserInfo, paymentUserStatus } from "./PaymentUserReducer";
import { analyticsList, analyticsSummary } from "./ReportReducer";
import {
  categoryNames,
  leastSellingChart,
  leastSellingRequestParameters,
  tableForSoldItems,
  topSellingChart,
  topSellingRequestParameters,
  totalOrderChart,
  totalOrderRequestParameters,
} from "./AnalyticsReducer";
import {
  botMessagesConfiguration,
  currentSelectedMessageChannel,
  globalConfiguration,
  isSkipped,
  transactionsExchanged,
  organizationConfig,
  settingsConfiguration,
} from "./SettingsReducer";
import {
  barStationList,
  barTenderList,
  isOpenBarTenderRight,
  isOpenListOfTender,
  selectedBarTender,
  tendersWithTipList,
} from "./BarTenderReducer";
import { allDayTipList, tipList } from "./TipReducer";
import { twoInOnePOSState } from "./TwoInOneReducer";
import { stationMasterList } from "./MasterSlaveReducer";
import { manageStationList } from "./ManageStationReducer";
import { ManagePrinters, ManageUnNamedPrinters } from "./ManagePrinters";
import { floorListReducer } from "./FloorManagementReducer";
import { variousReducer } from "./VariousItemsReducer";
import { menuCollapseIndex } from "./menuCollapseIndex";
import { fileUploadReducer } from "./FileUploadReducer";
import { notificationReducer } from "./NotificationReducer";
import { walkInCustomerReducer } from "./WalkInCustomerReducer";

const mainReducer = combineReducers({
  loadingBar: loadingBarReducer,
  isFullScreen: isFullScreen,
  forgotPasswordStatus: forgotPassword,
  fileUploadReducer: fileUploadReducer,
  forgotPasswordInfo: forgotPasswordInfo,
  customerList: customerList,
  orderHistory: orderHistoryReducer,
  byPassPaymentList: byPassPaymentList,
  menuList: menuList,
  acceptanceStatus: acceptanceStatus,
  liveOrderList: liveOrderList,
  paymentUserInfo: paymentUserInfo,
  paymentUserStatus: paymentUserStatus,
  analyticsSummary: analyticsSummary,
  analyticsList: analyticsList,
  tableForSoldItems: tableForSoldItems,
  categoryNames: categoryNames,
  menuCollapseIndex: menuCollapseIndex,
  totalOrderChart: totalOrderChart,
  topSellingChart: topSellingChart,
  leastSellingChart: leastSellingChart,
  totalOrderRequestParameters: totalOrderRequestParameters,
  topSellingRequestParameters: topSellingRequestParameters,
  leastSellingRequestParameters: leastSellingRequestParameters,
  settingsConfiguration: settingsConfiguration,
  botMessagesConfiguration: botMessagesConfiguration,
  globalConfiguration: globalConfiguration,
  transactionsExchanged: transactionsExchanged,
  organizationConfig: organizationConfig,
  isSkipped: isSkipped,
  selectedLiveOrder: selectedLiveOrder,
  barTenderList: barTenderList,
  selectedBarTender: selectedBarTender,
  tipList: tipList,
  isOpenBarTenderRight: isOpenBarTenderRight,
  allDayTipList: allDayTipList,
  isOpenListTender: isOpenListOfTender,
  tendersWithTipList: tendersWithTipList,
  isOpenLiveOrder: isLiveOrderOpen,
  currentSelectedMessageChannel: currentSelectedMessageChannel,
  twoInOnePOS: twoInOnePOSState,
  stationMasterList: stationMasterList,
  barStationList: barStationList,
  manageStationList: manageStationList,
  queueOrdersList: queueOrdersList,
  orderByIdDetails: orderDetails,
  managePrinters: ManagePrinters,
  manageUnNamedPrinters: ManageUnNamedPrinters,
  floorList: floorListReducer,
  variousList: variousReducer,
  notificationList: notificationReducer,
  walkInCustomer: walkInCustomerReducer,
});

const rootReducer = (state, action) => {
  if (action.type === ActionConstants.USER_LOGOUT) {
    state = undefined;
  }
  return mainReducer(state, action);
};

export default rootReducer;
