export const API_URL = process.env.REACT_APP_API_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const ROUTE_PREFIX = process.env.REACT_APP_ROUTE_PREFIX;

export const SOCIAL_PAGE_WHATSAPP_NUMBER =
  process.env.REACT_APP_WHATSAPP_NUMBER;
export const SOCIAL_PAGE_SMS_NUMBER = process.env.REACT_APP_SMS_NUMBER;

export const MESSENGER_ID = process.env.REACT_APP_MESSENGER_ID;

export const SOCIAL_PAGE_ADDRESS = {
  addressLineOne: "Oudebeurs 8",
  addressLineTwo: "2000 Antwerpen",
  addressLineThree: "Belgium",
};

// Quick Soft Drinks in items
export const SHOW_QUICK_ITEMS = "SOFTDRINKS";

// localStore encryption key
export const SECRET_KEY = "9j+]GJ&^4hIKZOk";

export const RIGHT_DRAWER_WIDTH = 400;
export const LEFT_DRAWER_WIDTH = 250;

export const TWO_IN_ONE_LEFT_DRAWER_WIDTH = 250;
export const TWO_IN_ONE_RIGHT_DRAWER_WIDTH = 300;

export const SUCCESS_NOTIFICATION_COLOR = "#00A65A";
export const ERROR_NOTIFICATION_COLOR = "#DD4B39";
export const WARNING_NOTIFICATION_COLOR = "#F39C12";
export const NOTIFICATION_TIMEOUT = 2000;

export const DARK_GRAY = "#707070";
export const CATEGORY_CHIP = "#6e3488";

export const CARD_GREEN_BG = "#6FCF97";
export const DARK_GREEN_BG =
  "transparent linear-gradient(245deg, #FFFFFF 0%, #C4EAD8 49%, #1BAE69 100%, #00A65A 100%) 0% 0% no-repeat padding-box";
export const GREEN_GRAD_BG =
  "transparent linear-gradient(270deg, #FFFFFF 0%, #6FCF97 100%) 0% 0% no-repeat padding-box";
export const CARD_DARK_GREEN_BG = "#588B8B";
export const DARK_GREEN_GRAD_BG =
  "transparent linear-gradient(89deg, #588B8B 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box";
export const CARD_BLUE_BG = "#274C77";
export const BLUE_GRAD_BG =
  "transparent linear-gradient(270deg, #FFFFFF 0%, #274C77 100%) 0% 0% no-repeat padding-box";
export const CARD_PASTEL_BLUE_BG = "#669BBC";
export const PASTEL_BLUE_GRAD_BG =
  "transparent linear-gradient(89deg, #669BBC 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box";
export const CARD_ORANGE_BG = "#F39C12";
export const ORANGE_GRAD_BG =
  "transparent linear-gradient(270deg, #FFFFFF 0%, #FF9500 100%) 0% 0% no-repeat padding-box";
export const CARD_RED_BG = "#EB5757";
export const RED_GRAD_BG =
  "transparent linear-gradient(270deg, #FFFFFF 0%, #EB5757 100%) 0% 0% no-repeat padding-box";
export const TRANSPARENT = "transparent";
export const WHITE_FONT = "#FFFFFF";
export const BLACK_FONT = "#000000";

export const GREEN_BG = "#00A65A";
export const ORANGE_BG = "#F39C12";
export const RED_BG = "#DD4B39";

export const BG_COLOR = "#ECF0F5";

export const COMMON_BACKGROUND = "#3C9FFA";
export const LINEAR_COMMON_BACKGROUND =
  "linear-gradient(89.95deg, #56CCF2 -20.04%, #2F80ED 99.96%)";

export const NOTIFICATION_TYPE = {
  success: "success",
  error: "error",
  warning: "warning",
  custom: "custom",
};

export const FORGOT_PASSWORD_STATUS = {
  IDEAL: "IDEAL",
  IN_PROGRESS: "IN_PROGRESS",
  OTP_VERIFIED: "OTP_VERIFIED",
  USER_ERROR: "USER_ERROR",
  DONE: "DONE",
};

export const CARD_TYPE = {
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
  DEFAULT: "DEFAULT",
};
export const RED_STATUS = {
  backgroundColor: RED_BG,
  borderRadius: 5,
  color: "#FFF",
  fontSize: 16,
  paddingLeft: 5,
  paddingRight: 5,
};

export const ORANGE_STATUS = {
  backgroundColor: ORANGE_BG,
  borderRadius: 5,
  color: "#FFF",
  fontSize: 16,
  paddingLeft: 5,
  paddingRight: 5,
};

export const GREEN_STATUS = {
  backgroundColor: GREEN_BG,
  borderRadius: 5,
  color: "#FFF",
  fontSize: 16,
  paddingLeft: 5,
  paddingRight: 5,
};

export const leftCombinePOSDrawerScreen = {
  tableListingScreen: "tableListingScreen",
  categoriesListingScreen: "categoriesListingScreen",
  itemListingScreen: "itemListingScreen",
};

export const floorScreenController = {
  floorListing: "floorListing",
  floorTablesDesign: "floorTablesDesign",
};

export const rightCombinePOSDrawerScreen = {
  emptyScreen: "emptyScreen",
  userListingScreen: "userListingScreen",
  orderCreationScreen: "orderCreationScreen",
};

export const rightCombineByPassScreen = {
  emptyScreen: "emptyScreen",
  userListingScreen: "userListingScreen",
  itemsListScreen: "itemsListScreen",
  requestFullPayment: "requestFullPayment",
  settleBillScreenRootScreen: "settleBillScreenRootScreen",
  settleBillScreen: "settleBillScreen",
  splitEqualityScreen: "splitEqualityScreen",
  splitByCustomersScreen: "splitByCustomersScreen",
  payingCustomerScreen: "payingCustomerScreen",
  splitByItemsCustomerScreen: "splitByItemsCustomerScreen",
  splitByItemsScreen: "splitByItemsScreen",
};

export const rightCombineWalkInScreen = {
  userGroupListingScreen: "userGroupListingScreen",
  userGroupItemsListScreen: "userGroupItemsListScreen",
  requestFullPayment: "requestFullPayment",
  settleBillScreenRootScreen: "settleBillScreenRootScreen",
  settleBillScreen: "settleBillScreen",
  splitEqualityScreen: "splitEqualityScreen",
  splitByCustomersScreen: "splitByCustomersScreen",
  payingCustomerScreen: "payingCustomerScreen",
  splitByItemsCustomerScreen: "splitByItemsCustomerScreen",
  splitByItemsScreen: "splitByItemsScreen",
};

export const stationType = {
  singleStation: "Single Station",
  multiStation: "Multi Station",
};

export const notificationType = {
  ORDER_GENERATED: "ORDER_GENERATED",
  PAYMENT_RECEIVED: "PAYMENT_RECEIVED",
};
