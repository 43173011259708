import { rightCombineByPassScreen } from "../constants/AppConstants";
import * as ActionConstants from "../constants/ReduxConstants";

const initialState = {
  byPassList: [],
  changeTableList: [],
  activeRightScreen: rightCombineByPassScreen.emptyScreen,
  orderData: [],
  selectedOrderData: [],
  selectedFloorId: "",
  isSplitByItems: false,
  isAllItemsSelected: false,
  selectedItemsList: [],
};

export const byPassPaymentList = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.BYPASS_PAYMENT_LIST:
      return {
        ...state,
        byPassList: action.data,
      };

    case ActionConstants.CHANGE_TABLE_LIST:
      return {
        ...state,
        changeTableList: action.data,
      };

    case ActionConstants.BP_RIGHT_CHANGE_SCREEN:
      return {
        ...state,
        activeRightScreen: action.data,
      };

    case ActionConstants.SELECT_TABLE_ORDER_DATA:
      return {
        ...state,
        orderData: action.data,
      };

    case ActionConstants.SELECT_ORDER_DATA:
      return {
        ...state,
        selectedOrderData: action.data,
      };
    case ActionConstants.RESET_BY_PASS_STATE:
      return initialState;

    case ActionConstants.MANAGE_FLOOR_ID:
      return {
        ...state,
        selectedFloorId: action.data,
      };

    case ActionConstants.IS_ITEMS_CHECKOUT:
      return {
        ...state,
        isSplitByItems: action.data,
      };

    case ActionConstants.IS_ALL_ITEMS_SELECTED:
      return {
        ...state,
        isAllItemsSelected: action.data,
      };

    case ActionConstants.SELECTED_ITEMS_LIST:
      return {
        ...state,
        selectedItemsList: action.data,
      };

    case ActionConstants.REFRESH_ORDER_DATA:
      return {
        ...state,
        orderData: state.orderData.items
          ? {
              ...state.orderData,
              items: state.orderData.items.map((item) => {
                if (item._id === action.data.id) {
                  return {
                    ...item,
                    isVerified: action.data.isVerified,
                    isVerificationInProgress:
                      action.data.isVerificationInProgress,
                    verifiedFrom: action.data.verifiedFrom,
                  };
                }
                return item;
              }),
            }
          : state.orderData,
        selectedOrderData:
          action.data.id === state.selectedOrderData._id
            ? {
                ...state.selectedOrderData,
                isVerified: action.data.isVerified,
                isVerificationInProgress: action.data.isVerificationInProgress,
                verifiedFrom: action.data.verifiedFrom,
              }
            : state.selectedOrderData,
      };

    default:
      return state;
  }
};
